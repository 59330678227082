import React from 'react'
import { SERVER_DATE_FORMAT, DATE_FORMAT } from 'helpers'
import { format } from 'helpers/date'
import { computed, observable } from 'mobx'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { Popup } from 'semantic-ui-react'
import { Casts, Model, Store } from 'store/Base'
import { BusinessRelation } from './BusinessRelation'
import { ExactSalesOrder } from './Exact/SalesOrder'
import { ExactGlobeSalesOrder } from './ExactGlobe/SalesOrder'
import { Unit4Order } from './Unit4/Order'
import { OutShipmentStore } from './OutShipment'
import { SalesOrderLineStore } from './SalesOrderLine'
import { Warehouse } from 'store/Warehouse'
import { NavisionSalesOrder } from 'store/NavisionSalesOrder'
import { NavisionProject } from 'store/NavisionProject'
import { SalesOrderResponsibilityStore } from 'store/SalesOrderResponsibility'
import { ShippingMethod } from './ShippingMethod'
import { PurchaseOrderStore } from './PurchaseOrder'
import { CarrierRequest } from './CarrierRequest';
import { LinkLabel } from '../component/LinkLabel'

export const STATUS_NO_STOCK = 'no_stock'
export const STATUS_OPEN = 'open'
export const STATUS_PARTIAL = 'partial'
export const STATUS_COMPLETE = 'complete'
export const STATUS_CANCELED = 'canceled'
export const STATUSES = [STATUS_OPEN, STATUS_PARTIAL, STATUS_COMPLETE, STATUS_CANCELED]
export const THREESTATUSES = [STATUS_NO_STOCK, STATUS_PARTIAL, STATUS_COMPLETE]

export class SalesOrder extends Model {
  static backendResourceName = 'sales_order'
  static idPrefix = 'SA'
  static idColor = 'green'
  static idIcon = 'local_atm_white'
  static omitFields = ['amountDelivered', 'clicked', 'mostRecentlyPickedDate']

  static PRIORITIES = ['high', 'medium', 'low']

  getUrl() {
    return `/demand/sales-order/${this.id}/edit`;
  }

  @observable id = null
  @observable status = STATUS_OPEN
  @observable externalSyncDatetime = null
  @observable externalSyncFailureReason = null
  @observable reference = ''
  @observable orderNumberErp = ''
  @observable remarks = ''
  @observable description = ''
  @observable creatorFullName = ''
  @observable orderDate = null
  @observable deliveryAddress = ''
  @observable deliveryDate = null
  @observable amountNoVat = 0
  @observable isProject = null
  @observable deleted = false
  @observable priority = 'medium'
  @observable erpId = ''
  @observable invoiceStatus = STATUS_OPEN
  @observable deliveryStatus = STATUS_OPEN
  @observable amountDelivered = 0
  @observable clicked = false
  @observable mostRecentlyPickedDate = null
  @observable markedColors = []

  @observable postalCode = null

  getIdIcon() {
    if (this.isProject) {
      return 'briefcase'
    } else {
      return 'local_atm_white'
    }
  }
  getIdPrefix() {
    if (this.isProject) {
      return 'P'
    } else {
      return 'SA'
    }
  }
  getIdColor() {
    if (this.isProject) {
      return 'brown'
    } else {
      return 'green'
    }
  }

  getLineFocusLink(props = {}) {
    props = {
      as: Link,
      to: `/demand/sales-order/${this.id}/edit?focus=salesOrderLines`,
      style: { textDecoration: 'none' },
      ...props,
    }
    return this.getLabel(props)
  }

  getMiniTag() {
    return window.viewStore.tracyTagSize === 'mini'
  }

  getIdLabel(showDate = false, { progressViewLink = false } = {}) {
    const idIcon = 'link'
    const idColor = 'red'
    const visitedColor = 'green'
    let to = this.getUrl()

    if (progressViewLink) {
      to = '?limit=25&currentPage=1&.erp_number=' + this.orderNumberErp
    }

    return (
      <Popup
        trigger={
          <LinkLabel
            id={this.id}
            to={to}
            icon={idIcon}
            colorOff={idColor}
            colorOn={visitedColor}
            content={this.orderNumberErp || this.id}
            salesOrder={this}
          />
        }
        content={<>{t('productionRequest.overview.linkedSalesOrder', { order: this.orderNumberErp })}{showDate ? `\nDelivery date: ${format(this.deliveryDate, DATE_FORMAT)}` : ''}</>}
      />
    )
  }

  relations() {
    return {
      customer: BusinessRelation,
      lines: SalesOrderLineStore,
      outShipments: OutShipmentStore,
      exactSalesOrder: ExactSalesOrder,
      exactGlobeSalesOrder: ExactGlobeSalesOrder,
      unit4Order: Unit4Order,
      warehouse: Warehouse,
      navisionSalesOrder: NavisionSalesOrder,
      navisionProject: NavisionProject,
      responsibilities: SalesOrderResponsibilityStore,
      shippingMethod: ShippingMethod,
      purchaseOrders: PurchaseOrderStore,
      carrierRequest: CarrierRequest
    }
  }

  getErpLinks() {
    return <>
      {!this.exactSalesOrder?.isNew && this.exactSalesOrder?.getLink()}
      {!this.unit4Order?.isNew && this.unit4Order?.getLink()}
      {!this.navisionSalesOrder?.isNew && this.navisionSalesOrder?.getLink()}
      {!this.exactGlobeSalesOrder?.isNew && this.exactGlobeSalesOrder?.getLink()}
    </>
  }

  casts() {
    return {
      externalSyncDatetime: Casts.datetime,
      orderDate: Casts.date,
      deliveryDate: Casts.date,
      mostRecentlyPickedDate: Casts.date,
    }
  }

  @computed
  get _status() {
    return t(`salesOrder.field.status.value.${this.status}`)
  }

  @computed
  get planningStatus() {
    const today = moment().format(SERVER_DATE_FORMAT)

    const states = []

    // red: Overdue Receipts. Receipt date overdue, no shipment paired.
    if (format(this.deliveryDate, SERVER_DATE_FORMAT) < today && this.outShipments.length === 0) {
      states.push('overdue_receipts')
    }

    // orange: Overdue Requests. Shipements overdue (order with shipment paired that is overdue).
    if (format(this.deliveryDate, SERVER_DATE_FORMAT) < today && this.outShipments.length > 0) {
      states.push('overdue_requests')
    }

    // green: Receipts This week. Shipments coming this week.
    if (moment(this.deliveryDate).isSame(new Date(), 'week')) {
      states.push('receipts_this_week')
    }

    // gray: Future Receipts. Shipments upcoming after this week.
    if (moment(this.deliveryDate).isAfter(new Date(), 'week')) {
      states.push('future_receipts')
    }

    return states

    // const today = moment().format(SERVER_DATE_FORMAT);
    // let status = 'normal';

    // if (
    //     format(this.deliveryDate, SERVER_DATE_FORMAT) < today &&
    //     [STATUS_OPEN, STATUS_PARTIAL].includes(this.status)
    // ) {
    //     if (this.outShipments && this.outShipments.length === 0) {
    //         status = 'danger';
    //     } else {
    //         status = 'warning';
    //     }
    // }

    // return status;
  }

  // TODO, move to annotation in backend. Calculating on frontend needs to much data
  @computed get hasUnallocatedDeficit() {
    return true;
    // return this.lines.models.some((line) => line.hasUnallocatedDeficit)
  }

  toBackendAll(...args) {
    const data = super.toBackendAll(...args)

    // eslint-disable-next-line
    for (const out_shipment_line of data.relations.out_shipment_line || []) {
      if (out_shipment_line.id >= 0) {
        continue
      }
      // out_shipment_line is new; try and attach superrequest to the line
      const out_shipment = data.relations.out_shipment.find(({ lines }) => lines.includes(out_shipment_line.id))
      const production_request = data.relations.production_request.find(({ id }) => id === out_shipment_line.production_request)
      if (out_shipment.production_request) {
        production_request.superrequest = out_shipment.production_request
      } else {
        // superrequest not found on data, try via the model
        const out_shipment_from_model = this.outShipments.find(({ id }) => id === out_shipment.id)
        production_request.superrequest = out_shipment_from_model?.productionRequest?.id
      }
    }

    return data
  }

  allocateFromCurrentStock() {
    return this.api.post(`${this.url}allocate_from_current_stock/`)
  }

  pickableLines() {
    /**
     * returns sales order lines that:
     * - have quantity available that is not picked yet
    */
    return this.lines.models.filter((line) =>
      !([STATUS_COMPLETE, STATUS_CANCELED].includes(line.deliveryStatus)) && line.toPick > 0
    )
  }

  createPickOrders(options) {
    return this.api.post(`${this.url}create_pick_orders/`, null, options)
  }

  /**
   *
   * @returns link to perform-sync for this sales order, or null
   */
  getSyncRequestFilterLink() {
    let integrationId = null;
    let erpId = null;
    if (!this.exactSalesOrder?.isNew && this.exactSalesOrder?.integration) {
      integrationId = this.exactSalesOrder.integration.id
      erpId = this.exactSalesOrder.number
    }

    if (integrationId && erpId) {
      return `/assets/integration/${integrationId}/perform-sync/overview?.erp_id=${erpId}`
    } else if (integrationId) {
      return `/assets/integration/${integrationId}/perform-sync/overview`
    } else {
      return null
    }
  }
}

export class SalesOrderStore extends Store {
  static backendResourceName = 'sales_order'
  Model = SalesOrder

  @observable _statusTotals = null
  @observable _requestStatusTotals = null
  @observable _receiptStatusTotals = null

  fetchStatusTotals({ mapParams = (params) => params } = {}) {
    return this.api.get(this.url() + 'status_totals/', mapParams(this.params)).then((res) => {
      this._requestStatusTotals = res.data.request_status
      this._receiptStatusTotals = res.data.receipt_status
      this._statusTotals = res.data.status
    })
  }
}
